.flagContainer {
    position: absolute;
    top: 10%;
    bottom: 10%;
    background-color: #fff;
    border: 1px solid #cfcfcf;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.4s linear;
    z-index: 10000;
}

.flagContainer {
    left: -1000px;
    width: 600px;
}

.toggle {
    .main {
        left: 700px;
        opacity: 0.4;
    }

    .flagContainer {
        left: 0;
    }
}

.panel {
    width: 70%;
    background-color: #8ec16d;
    text-align: center;
    margin: 20px auto;
    font-size: 17pt;
    padding: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    font-family: sans-serif;
    color: #fff;

    &:hover {
        cursor: pointer;
        background-color: #9dc980;
    }

    &.selected {
        background-color: #e77;
    }
}

.toggles {
    position: fixed;
    left: .1rem;
    top: .1rem;
    cursor: pointer;
    z-index: 1000;
}

.feature-flags {
    h1 {
        font-size: 2rem;
        text-align: center;
    }
    .feature-flags-flag {
        padding: 10px 30px;
        transition: all 0.2s linear;
    }

    .feature-flags-name {
        font-size: 1.2em;
        float: left;
    }

    .feature-flags-switch {
        float: right;
        padding: 2px 10px;
        cursor: pointer;
    }

    .feature-flags-switch.active {
        font-weight: bold;
        border: 1px solid currentcolor;
        padding: 1px 9px;
    }

    .feature-flags-desc {
        color: #aaa;
        clear: both;
        margin: 0 20px;
    }
}
