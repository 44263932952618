/*! =========================================================
 *
 * Material Kit PRO - V1.0.0
 *
 * =========================================================
 *
 * Copyright 2016 Creative Code Srl
 * Available with purchase of license from http://www.creative-tim.com/product/material-kit-pro
 *
 * ========================================================= */

@import 'material-kit/variables';
@import 'material-kit/mixins';
@import 'material-kit/shadows';
@import 'material-kit/misc';

// core sass
@import 'material-kit/form';
@import 'material-kit/buttons';
@import 'material-kit/checkboxes';
@import 'material-kit/togglebutton';
@import 'material-kit/radios';
@import 'material-kit/inputs';
@import 'material-kit/pagination';
@import 'material-kit/labels';
@import 'material-kit/pills';

@import 'material-kit/navbar';
@import 'material-kit/dropdown';
@import 'material-kit/alerts';
@import 'material-kit/progress';
@import 'material-kit/typography';
@import 'material-kit/tabs';
@import 'material-kit/popups';
@import 'material-kit/carousel';
@import 'material-kit/cards';
@import 'material-kit/dialogs';
@import 'material-kit/panels';
@import 'material-kit/dividers';

@import 'material-kit/images';
@import 'material-kit/media';
@import 'material-kit/info-areas';
@import 'material-kit/tables';
@import 'material-kit/select';
@import 'material-kit/tags';
@import 'material-kit/fileupload';
@import 'material-kit/flexisel';


@import 'material-kit/sections';

// External plugins
@import 'material-kit/plugins/plugin-nouislider';
@import 'material-kit/plugins/plugin-selectize';
@import 'material-kit/plugins/plugin-datepicker';

@import 'material-kit/ripples';
@import 'material-kit/example-pages';

@import 'material-kit/responsive';
