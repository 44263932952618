ulm-header md-toolbar {
    padding: 5px 0;

    .md-button {
        font-size: 13px;
        margin: 0 4px;

        &:hover, &.active {
            color: $brand-primary;
            background-color: transparent !important;
            md-icon { color: inherit !important; }
        }

        &:focus, &:active {
            color: $brand-primary;
            background-color: rgba(244,116,108,.1) !important;
            md-icon { color: inherit !important; }
        }

        md-icon, .md-icon {
            font-size: 20px !important;
        }

        .fa {
            font-size: 18px;
            max-width: 20px;
            position: relative;
            top: 2px;
            margin-right: 4px;
        }
    }

    .md-button.md-success {
        color: $brand-success;

        &:hover, &.active, &:focus, &:active {
            color: $brand-success;
            background-color: rgba(3,174,120,.1) !important;
        }
    }

}




.navbar.navbar-default {
    color: $text-color;
    background-color: $white;
    box-shadow: none;
    padding: 12px 0;
    margin-bottom: 0;
    border-radius: 0;

    .navbar-nav > li > a {
        padding: 12px 24px;
        font-weight: 400;
        border-radius: 100px;
        border: 1px solid transparent;

        &:hover { color: $brand-primary; }
        &.active { color: $brand-primary; }
    }

    .navbar-brand {
        height: 55px;
        padding: 0 15px;
        border-radius: 55px; // for containing the ripple in a pill shape

        img {
            max-height: 50px;
        }
    }

    .navbar-nav.navbar-right {
        margin-top: 5px;
    }
}

.navbar-nav > li > a:focus {
    background-color: transparentize($brand-primary, .90) !important;
    outline: none;
}

@media (max-width: $screen-xs-min) {
    .navbar.navbar-default .navbar-brand {
        width: 270px;
        padding: 8px 15px 8px 0;
    }
    .navbar.navbar-default {
        margin: 0 -15px;
    }

    .navbar .navbar-toggle {
        margin-right: -10px;
    }

}

@media (max-width: $screen-xs-max) {
    .navbar .navbar-toggle {
        margin: 0;
        i { font-size: 34px; }
    }

    .navbar .nav.navbar-nav {
        margin-bottom: -5px;
    }

    .navbar .navbar-nav > li {
        margin: 0;
    }

    .navbar.navbar-default .navbar-nav > li > a {
        padding: 12px 14px;
    }

    .navbar-collapse {
        max-height: 0;
        height: auto;
        transition: all 900ms cubic-bezier(.1, .25, .1, 1);
        overflow-y: hidden;
    }

    .navbar-collapse.in {
        overflow-y: hidden;
        max-height: 300px;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .navbar.navbar-default .navbar-nav > li > a {
        padding: 12px 14px;
    }
    .navbar .navbar-nav > li {
        margin-left: 8px;
        margin-right: 8px;
    }
    .navbar-header {
        width: 320px;
        margin: 0 auto;
        float: none;
    }
    .navbar-right {
        float: none !important;
        margin: 0 auto;
        width: 677px;
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .navbar.navbar-default .navbar-nav > li > a {
        padding: 12px 14px;
    }
    .navbar-header {
        width: 320px;
    }
}

@media (min-width: $screen-lg-min) {
    .container-lg-only {
        width: 1170px;
    }
}
