@import 'variables';

// Vendor (could be split out later for faster builds)
@import url('//fonts.googleapis.com/icon?family=Material+Icons');
@import url('//fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700|Roboto+Mono');
@import '../../../../node_modules/font-awesome/scss/font-awesome';

// To be deprecated
@import '../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../../vendor/material-kit-pro/material-kit';

// Global
@import 'mixins';
@import 'spacers';
@import 'global';
@import 'helpers';
@import 'hacks';

// Component Customization
@import 'components/mdButton';
@import 'components/mdDialog';
@import 'components/mdIcon';
@import 'components/mdLinearProgress';
@import 'components/mdStepper';
@import 'components/mdTable';
@import 'components/mdToast';

// Components
@import 'components/alerts';
@import 'components/dropdown';
@import 'components/fab';
@import 'components/featureFlags';
@import 'components/forms';
@import 'components/globalBanner';
@import 'components/linkModal';
@import 'components/navbar';
@import 'components/register';
@import 'components/tables';
@import 'components/thumbnailField';
@import 'components/thumbnailModal';
@import 'components/typeahead';

// Layouts
@import 'layouts/login';
