.thumbnailField--preview {
    @extend .mbl;
    @include shadow-3dp();
}

.thumbnailField--previewWrapper {
    position: relative;
    display: inline-block;
    margin-top: -20px;
}

.thumbnailField--removeBtn {
    position: absolute;
    top: -5px;
    right: -5px;

    .md-icon-button {
        @include shadow-2dp();
        background-color: #fff;

        md-icon {
            color: rgb(255, 170, 170);
        }
    }

    .md-icon-button:hover {
        background-color: #fff !important;
    }

}
