.nbs-flexisel-container {
    position:relative;
    min-width:100%;
}
.nbs-flexisel-ul {
    position:relative;
    width:9999px;
    margin:0px;
    padding:0px;
    list-style-type:none;
    text-align:center;
}

.nbs-flexisel-inner {
    overflow:hidden;
    width:100%;
}

.nbs-flexisel-item {
    float:left;
    margin:0px;
    padding:0px;
    cursor:pointer;
    position:relative;
    line-height:0px;
}
.nbs-flexisel-item img {
    width: 100%;
    cursor: pointer;
    position: relative;
/*
    margin-top: 10px;
    margin-bottom: 10px;
*/

}

/*** Navigation ***/

.nbs-flexisel-nav-left,
.nbs-flexisel-nav-right {
    position: absolute;
    cursor: pointer;
    z-index: 100;
    opacity: 0.5;
}

.nbs-flexisel-nav-left,  {
    left: -10px;
}

.nbs-flexisel-nav-right {
    right: -10px;
}
