.md-stepper-title {
    line-height: 26px;
}

.md-stepper-indicator.md-active .md-stepper-title, .md-stepper-indicator.md-editable .md-stepper-title {
    font-weight: 600;
}

.md-stepper-number {
    width: 26px;
    height: 26px;
    line-height: 26px;
}

md-step-body {
    padding-top: 12px;

    md-input-container {
        margin-bottom: 0;
    }

    md-radio-group {
        margin-left: 16px;
    }
}

// fix left padding to *total* 24dp and match the material design spec
.md-steppers-vertical .md-stepper {
    padding: 8px 24px 8px 18px;
}
