.md-button {
    letter-spacing: 0;
}

.md-button.md-raised.md-success, .md-altTheme-theme.md-button.md-raised.md-success {
    background-color: $brand-success;
    color: white;

    &:hover {
        background-color: $brand-success;
    }

    &[disabled] {
    opacity: .5;
}
}

.md-button.md-raised {
    border-radius: 200px;
    padding: 3px 28px;
    font-size: 13px;

    &:not([disabled]) {
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12); // .md-whiteframe-1dp
        &:focus {
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12); // .md-whiteframe-3dp
        }
        &:hover {
            box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12); // .md-whiteframe-6dp
        }
    }

    > md-icon {
        margin-left: -9px;
        font-size: 20px;
    }
}

.md-button.md-muted {
    color: #777;
}

.md-button .md-ripple-container {
    border-radius: 200px;
}

.md-button[disabled] {
    cursor: not-allowed !important;
}

.md-table > thead .md-column > .md-icon-button > md-icon {
    font-size: 24px !important;
    line-height: 24px !important;
}

.md-button-with-icon {
    .material-icons {
        margin-left: -8px;
        vertical-align: middle;
        font-size: 17px;
        top: -1px;
        position: relative;
    }
}
