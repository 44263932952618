md-toast.md-middle {
    left: 50%;
    transform: translate(-50%, 0);
    right: auto;
}

md-toast.md-toast-success .md-toast-content {
    background-color: $brand-success;
}

md-toast.md-toast-error .md-toast-content {
    background-color: $brand-danger;
}


md-toast.md-default-theme .md-toast-content {
    border-radius: 200px;
}
