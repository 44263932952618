.table > thead > tr > th {
    font-weight: 400;
}

hm-shortlink-domains-table .is-domain-verified {
    margin-left: -8px !important;
}

.is-domain-verified {
    margin: 0 !important;

    md-icon {
        font-size: 20px;
    }
}
