.dropdownjs {
  position: relative;
}
.dropdownjs  * {
  box-sizing: border-box;
}
.dropdownjs > input {
  width: 100%;
  padding-right: 30px;
  text-overflow: ellipsis;
}

.dropdownjs > ul {
    @include transition($fast-transition-time, $transition-linear);
    display: block;
    margin-top: -20px;
    min-width: 200px;
    opacity: 0;
    padding: 0;
    position: absolute;
    visibility: hidden;
    z-index: 10000;
}
.dropdownjs > input.focus ~ ul {
    opacity: 1;
    visibility: visible;
    margin-top: 1px;
}
.dropdownjs > ul  > li {
  list-style: none;
  padding: 10px 20px;
}
.dropdownjs > ul  > li.dropdownjs-add {
  padding: 0;
}
.dropdownjs > ul  > li.dropdownjs-add > input {
  border: 0;
  padding: 10px 20px;
  width: 100%;
}

/* Theme */
.dropdownjs > input[readonly] {
  cursor: pointer;
}
select[data-dropdownjs][disabled] + .dropdownjs > input[readonly] {
  cursor: default;
}
.dropdownjs > ul {
  background: #FFF;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  padding: 5px 0px;
  overflow: auto;
  max-width: 500px;
  
}
.dropdownjs > ul > li {
    cursor: pointer;
    word-wrap: break-word;
    font-size: $mdb-dropdown-font-size;
    font-weight: $font-weight-default;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: $border-radius-small;
    @include transition($fast-transition-time, $transition-linear);
}
.dropdownjs > ul > li {
        .selected,
        &:active,
        &:focus,
        &:hover{
            @include shadow-8dp();
            background-color: $brand-primary;
            color: #FFFFFF;
        }
}
.dropdownjs > ul > li > .close:before {
  content: "\00d7";
  display: block;
  position: absolute;
  right: 15px;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .6;
}
.dropdownjs > ul > li:h > .close:hover:before {
  opacity: .9;
}
.dropdownjs::after {
  right: 10px;
  top: 20px;
  font-size: 25px;
  position: absolute;

  // bring in the material icon font and icon by code
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  content: "\e5c5";
  pointer-events: none;
  color: #fff;
}

