register {
    margin-bottom: -65px;
    display: block;

    fieldset {
        @extend .pbm;
    }

    legend {
        margin-top: 20px;
        margin-bottom: 18px;
        padding-left: 3px;
    }
    md-input-container {
        margin: 10px 0;

        .help-block .material-icons {
            font-size: 16px;
            position: relative;
            top: 3px;
        }
    }
}

.register__billing-card-icon {
    @extend .mrs;
    @extend .mlxs;
    max-width: 160px;
    max-height: 37px;
    margin-top: 25px;
}

.register__security-banner {
    display: inline-table;
    width: 100%;
    font-family: courier, monospace;
    text-align: center;
    padding: 6px;
    background-color: #efe;
    color: #353;
    border: 1px solid #d5f1d5;
    border-right-width: 0;
    border-left-width: 0;
    font-size: .95em;
    position: relative;
    bottom: -21px;
    .material-icons {
        position: relative;
        top: 3px;
        font-size: 1.1em;
        margin: 0 8px;
    }
}

.register__wrapper {
    flex-basis: 740px;
    margin: 74px 20px 10px;
}

.register__form-wrapper {
    background-color: $white;
    border-radius: $stepper-borderRadius;
    box-shadow: $shadow-z1;
    margin-bottom: 30px;
    padding: 30px 20px 20px;
}

.register__globalsign-seal {
    margin: 0 auto;
}
