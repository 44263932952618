.md-table tr:hover td {
    background: #eee;
}

table.md-table td.md-cell {
    border-top: 1px #E0E0E0 solid;
}

td.break {
    max-width: 33vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

td.cell-actions-three {
    min-width: 151px;

    .md-button {
        margin: 0 !important;
    }
}

md-table-pagination .label {
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    line-height: inherit;
    text-transform: none;
}

.md-table-footer {
    padding: 6px 24px;
    font-size: 12px;
    color: rgba(0,0,0,.54);
    border-top: 1px rgba(0,0,0,.12) solid;
}
