html, body {
    height: auto;
    min-height: 100vh;
}

img {
    max-width: 100%;
}

md-content {
    background-color: #fff;
}

main {
    min-height: calc(100vh - 157px); // where 157px is the height of the <footer> + height of the <header>
    overflow-x: hidden;
}

.error-text {
    font-size: 13px;
    line-height: 14px;
    color: rgb(177, 85, 0);
}

.pattern-page {
    ulm-header md-toolbar {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    }

    .main-content {
        background: url('/images/utm-link-pattern.png');
    }

    main {
        overflow: hidden;
    }

    #footer {
        margin-top: -3px;
        padding-bottom: 0;
        color: white;
    }
}
