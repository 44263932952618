.dropdown-menu {
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

    .divider {
        background-color: rgba(0, 0, 0, .12);
    }

    li > a {
        font-size: 13px;
        padding: 12px 20px;
        margin: 0;
        border-radius: 0;
        @include transition($fast-transition-time, $transition-linear);

        &:hover,
        &:focus {
            box-shadow: none;
        }

        &.active {
            font-weight: bold;
        }
    }

    &.dropdown-with-icons {
        li > a {
            padding: 12px 20px 12px 12px;

            .material-icons {
                vertical-align: middle;
                font-size: 24px;
                position: relative;
                margin-top: -4px;
                top: 1px;
                margin-right: 12px;
                opacity: .5;
            }
        }
    }

    li {
        position: relative;
        a:hover,
        a:focus,
        a:active {
            background-color: $grey-200;
            color: #333;
            outline: none;
        }
        a.active:hover,
        a.active:focus,
        a.active:active {
            outline: none;
            cursor: default;
        }
    }

    .divider {
        margin: 5px 0;
    }

    .navbar &,
    .navbar.navbar-default & {
        li {
            > a:hover,
            > a:focus {
                background-color: $grey-200;
                color: #333;
                outline: none;
            }
            > a.active:hover,
            > a.active:focus {
                outline: none;
                cursor: default;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .dropdown {
        .dropdown-menu {
            @include transition($fast-transition-time, $transition-linear);
            margin-top: -20px;
            opacity: 0;
            visibility: hidden;
            display: block;
        }

        &.open {
            .dropdown-menu {
                opacity: 1;
                visibility: visible;
                margin-top: 1px;
            }
        }
    }
}
