legend {
    margin-top: 20px;
    margin-bottom: 15px;
}

.card .header {
    margin-bottom: 30px;
}

.section-signup {
    padding-bottom: 10vh;
    min-height: 100vh;
    margin-top: -70px;
}

.card-signup .content {
    padding: 0 24px;
}

.alert {
    margin-top: 20px;
}

.alert-dismissable .close, .alert-dismissible .close {
    top: 1px;
    font-size: 18px;
    right: 0;
}

.input-group .input-group-addon.addon-text {
    color: #aaa;
    padding: 8px 10px 12px 0;
}

.card .header-primary {
    background: linear-gradient(60deg, $brand-primary, darken($brand-primary, 4));
}

.alert-inline {
    padding: 12px;
    margin: 12px 0;
}

.btn-loading {
    background-color: transparent !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, 0.26) !important;
    cursor: not-allowed;
    opacity: .65;
}

.btn.btn-with-icon {
    // used when a button has an icon *and* text
    //   avoids adjust buttons where it's *just* the icon
    .material-icons, .fa {
        margin-left: -8px;
    }
}

select ~ .control-label {
    // fixes the issue where label wouldn't float when it was for a <select>
    //   nb: requires the label to be placed after the <select> in the DOM
    top: -28px !important;
    font-size: 11px !important;
    line-height: 1.07143 !important;
}

.input-group ~ .control-label {
    // fixes the issue where label should float when it was for an input which has an addon
    //   nb: requires the label to be placed after the input in the DOM
    top: -28px !important;
    font-size: 11px !important;
    line-height: 1.07143 !important;
}

.dropdownjs > ul > li .selected, .dropdownjs > ul > li:active, .dropdownjs > ul > li:focus, .dropdownjs > ul > li:hover {
    background-color: $brand-info;
}

.td-actions-head {
    // made important since width is usually set in DOM by datatables.js
    width: 117px !important;
}

.modal-body.info {
    padding-top: 6px;
}

.input-group .input-group-btn {
    padding: 0 8px 0 12px;
}

.table {
    margin-bottom: 0;
    width: 100% !important;
}

// ULM-50
.help-block {
    background-color: rgba(255, 255, 255, .9);
    z-index: 1;
}

// MD Tables Sorting
.mdtTableContainer th mdt-sorting-icons svg {
    position: relative;
    top: 4px;
}

// ngMaterial / Angular Material / MD
.md-button.md-fab.md-fab-bottom-right {
    position: fixed;
    bottom: 86px;
    right: 14px;
}

.md-button {
    border-radius: 300px;
    padding: 3px 15px;
}

md-menu-content {
    max-height: 352px;
}

md-toast {
    position: fixed;
}

.md-icon-inline {
    color: inherit;
    font-size: 90%;
    left: -5px;
    line-height: 23px;
    position: relative;
}

.md-button.md-fab:not([disabled]) {
    transition: box-shadow .4s cubic-bezier(.25, .8, .25, 1), background-color .4s cubic-bezier(.25, .8, .25, 1);

    &:hover {
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    }
}

.radio-subtext {
    font-color: $brand-danger;
    margin-left: 44px;
    margin-top: -10px;

    md-icon {
        color: inherit;
        font-size: 17px;
        width: 17px;
        height: 17px;
        min-width: initial;
        min-height: initial;
        line-height: 14.5px;
        margin-right: 1px;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
}

.input-addon {
    padding: 8px 6px;
    color: rgba(0, 0, 0, 0.54);
}

md-input-container:first-child.input-addon {
    padding-left: 0;
}

.help-text-switch {
    width: 155px;
    margin: 0 0 -15px auto !important;
}
