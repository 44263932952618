
md-dialog {
    flex-basis: 540px;

    &.md-dialog-medium {
        flex-basis: 680px;
    }

    .md-dialog-content {
        padding: 0 16px;

        .md-title {
            margin-top: 17px !important;
        }
    }

    .md-actions, md-dialog-actions {
        padding: 12px 16px;
    }

    .md-actions .md-button, md-dialog-actions .md-button {
        margin: 0 0 8px;
    }

    .md-cancel-button { color: #777 !important; }
}

.md-content-overflow md-dialog-actions {
    padding: 18px 16px;
}

.md-dialog-container {
    max-height: 100vh;
}

.md-toolbar-tools {
    min-height: 64px;
}
