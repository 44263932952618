.linkModal {
    md-input-container {
        margin-top: 0;
    }
    md-input-container input {
        color: rgba(0, 0, 0, 0.87) !important;
    }

    .help-block.help-block--autocomplete {
        margin: -35px 3px 25px 3px;
    }
}
