.tagsinput {
    overflow-y: auto;
    text-align: left;
    .tag {
        background-color: $brand-default;
        cursor: pointer;
        border-radius: $border-radius-label;
        margin: 5px 3px 5px 0;
        padding: 5px 12px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: $font-weight-default;
        position: relative;
    }

    .tag:hover{
        padding-right: 14px;
    }

    .tagsinput-remove-link {
        color: $black-color;
        cursor: pointer;
        font-size: 1em;
        position: absolute;
        right: 0;
        opacity: 0;
        text-align: right;
        text-decoration: none;
        top: 0;
        width: 100%;
        z-index: 2;
    }
    .tag:hover .tagsinput-remove-link {
        opacity: 1;
        padding-right: 6px;
    }
    .tagsinput-remove-link:before {
        content: "\E5CD";
        font-family: 'Material Icons';
    }

    .tagsinput-add-container {
        display: inline-block;
        line-height: 1;
        margin-top: -6px;
        vertical-align: middle;
    }
    .tagsinput-add {
        cursor: pointer;
        display: inline-block;
        font-size: 1.2em;
        margin: 11px 0 0;
        vertical-align: top;
    }
    .tagsinput-add:before {
	    content: "\E148";
        font-family: 'Material Icons';
    }

    input{
        background: transparent;
        border: none;
        font-size: 1em;
        height: 30px;
        margin: 4px 2px 0 ;
        outline: medium none !important;
        vertical-align: middle;
        width: 40px;
    }
    
    @include create-colored-tags();

/*
    &.tag-primary .tag,
    &.tag-primary .tagsinput-remove-link{
         background-color: $primary-color;
    }
*/
/*
    &.tag-primary .tag{
         background-color: $primary-color;
    }
    &.tag-primary .tagsinput-add{
        color: $primary-color;
    }

    &.tag-success .tag{
         background-color: $success-color;
    }
    &.tag-warning .tag{
         background-color: $warning-color;
    }
    &.tag-danger .tag{
         background-color: $danger-color;
    }
*/
}
