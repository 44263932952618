footer{
    padding: $padding-base 0;
    text-align: center;

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base;
                font-weight: $font-weight-bold;
                font-size: $mdb-btn-font-size-base;
                text-transform: uppercase;
                border-radius: $border-radius-base;
                text-decoration: none;
                position: relative;
                display: block;

                &:hover{
                    text-decoration: none;
                }
            }

            .btn{
                margin: 0;
            }
        }

        &.links-horizontal{
            &:first-child a{
                padding-left: 0;
            }

            &:last-child a{
                padding-right: 0;
            }
        }

        &.links-vertical{
            li{
                display: block;
                margin-left: -5px;
                margin-right: -5px;
                
                a{
                    padding: 5px;
                }
            }
        }
    }

    .social-buttons{
        a,
        .btn{
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }

    .footer-brand{
        float: left;
        height: 50px;
        padding: 15px 15px;
        font-size: 18px;
        line-height: 20px;
        margin-left: -15px;

        &:hover,
        &:focus{
            color: $black-color;
        }
    }
    .copyright{
        padding: 15px 0;
        .material-icons{
            font-size: 18px;
            position: relative;
            top: 3px;
        }
    }

    .pull-center{
        display: inline-block;
        float: none;
    }
}

.footer-big{
    padding: $padding-base * 2 0;

    .content{
        text-align: left;
    }

    .social-feed{

        i{
            font-size: 20px;
            display: table-cell;
            padding-right: 10px;
        }
        p{
            display: table-cell;
            vertical-align: top;
            overflow: hidden;
            padding-bottom: 10px;
            max-width: 300px;
        }
    }

    .gallery-feed{
        img{
            width: 20%;
            margin-right: 5%;
            margin-bottom: 5%;
            float: left;
        }
    }
}
.footer-white{
    background-color: $white-color;
}
.footer-gray{
    background-color: $gray-lighter;
}
.footer-black{
    @include radial-gradient(#232323,#585858);

    a{
        color: $white-color;
        opacity: .86;

        &:hover,
        &:focus{
            opacity: 1;
        }
    }

    .footer-brand{
        color: $white-color;

        &:hover,
        &:focus{
            color: $white-color;
        }
    }

    h5,
    h4,
    i,
    .copyright{
        color: $white-color;
    }

    hr{
        border-color: rgba($white-color, .2);
    }
}
