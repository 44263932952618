// Colors
$brand-primary: #ff4940;
$brand-info: #2697da;
$brand-success: #03ae78;
$brand-danger: #e53935;
//$brand-warning: #fcce00;
$brand-warning: rgb(255, 135, 24);
$brand-secondary: #2ccca6;

$gray-dark: #333;

$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e;
$rgb-grey-500: "158, 158, 158" !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default;
$grey-800: #424242 !default;
$grey-900: #212121 !default;
$grey-A100: #f5f5f5 !default;
$grey-A200: #eee !default;
$grey-A400: #bdbdbd !default;
$grey-A700: #616161 !default;
$grey: $grey-500 !default;

$blue-grey-50: #eceff1 !default;
$blue-grey-100: #cfd8dc !default;
$blue-grey-200: #b0bec5 !default;
$blue-grey-300: #90a4ae !default;
$blue-grey-400: #78909c !default;
$blue-grey-500: #607d8b !default;
$blue-grey-600: #546e7a !default;
$blue-grey-700: #455a64 !default;
$blue-grey-800: #37474f !default;
$blue-grey-900: #263238 !default;
$blue-grey-A100: #cfd8dc !default;
$blue-grey-A200: #b0bec5 !default;
$blue-grey-A400: #78909c !default;
$blue-grey-A700: #455a64 !default;
$blue-grey: $blue-grey-500 !default;

$black: #000;
$rgb-black: "0,0,0" !default;
$white: #fff;
$rgb-white: "255,255,255" !default;

// Sizes
$xs: 5px;
$sm: 10px;
$md: 20px;
$lg: 30px;
$xl: 60px;

// Other (Internal)
$stepper-borderRadius: 3px;
$shadow-z1: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
0 1px 5px 0 rgba(0, 0, 0, 0.12),
0 3px 1px -2px rgba(0, 0, 0, 0.2);

// Other (Bootstrap Overrides)
$navbar-height: 75px;
$navbar-padding-vertical: 12px;
$link-color: $brand-info;
$text-color: $gray-dark;

// Bootstrap Sizing
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

// Material Kit Pro Overrides
$font-family-serif: Montserrat, sans-serif;
$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fast-transition-time: 150ms;
$transition-linear: linear;
$mdb-shadow-key-penumbra-opacity: .14;
$mdb-shadow-ambient-shadow-opacity: .12;
$mdb-shadow-key-umbra-opacity: .2;
