// Spacers //

// Margin Spacers

.man { margin: 0 }

.mtxs { margin-top: $xs }

.mts { margin-top: $sm }

.mtm { margin-top: $md }

.mtl { margin-top: $lg }

.mtxl { margin-top: $xl }

.mtn { margin-top: 0 }

.mrxs { margin-right: $xs }

.mrs { margin-right: $sm }

.mrm { margin-right: $md }

.mrl { margin-right: $lg }

.mrn { margin-right: 0 }

.mbxs { margin-bottom: $xs }

.mbs { margin-bottom: $sm }

.mbm { margin-bottom: $md }

.mbl { margin-bottom: $lg }

.mbxl { margin-bottom: $xl }

.mbn { margin-bottom: 0 }

.mlxs { margin-left: $xs }

.mls { margin-left: $sm }

.mlm { margin-left: $md }

.mll { margin-left: $lg }

.mln { margin-left: 0 }

// Padding Spacers

.pan { padding: 0; }

.ptxs { padding-top: $xs; }

.pts { padding-top: $sm; }

.ptm { padding-top: $md; }

.ptl { padding-top: $lg; }

.ptxl { padding-top: $xl; }

.ptn { padding-top: 0; }

.prxs { padding-right: $xs; }

.prs { padding-right: $sm; }

.prm { padding-right: $md; }

.prl { padding-right: $lg; }

.prn { padding-right: 0; }

.pbxs { padding-bottom: $xs; }

.pbs { padding-bottom: $sm; }

.pbm { padding-bottom: $md; }

.pbl { padding-bottom: $lg; }

.pbxl { padding-bottom: $xl; }

.pbn { padding-bottom: 0; }

.plxs { padding-left: $xs; }

.pls { padding-left: $sm; }

.plm { padding-left: $md; }

.pll { padding-left: $lg; }

.pln { padding-left: 0; }
