.section-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 79px;
    margin: -109px -8px -30px;
}

.signup-form-card {
    flex-basis: 480px;
    margin: 50px 10px 20px 10px;
}

.card-heading {
    text-align: left;
    border-left: solid 4px $brand-secondary;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    padding: 8px 18px;
    margin-top: 20px;
    color: #3c4858;
}
