.typeahead {
    //@extend .dropdown-menu;
    @extend .md-whiteframe-2dp;
    opacity: 1;
    visibility: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    transition: all 150ms linear;
    display: block;
    position: absolute;
    z-index: 1000;
    float: left;
    min-width: 160px;
    width: 100%;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: $grey-50;
    border-radius: 0;
    background-clip: padding-box;
}

.typeahead > .active > a,
.typeahead > .active > a:hover,
.typeahead > .active > a:focus {
    text-decoration: none;
    outline: 0;
    background-color: $grey-200;
}

.typeahead li {
    position: relative;
}

.typeahead li > a {
    font-size: 14px;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    transition: all 150ms linear;
    display: block;
    clear: both;
    font-weight: normal;
    color: #333;
    white-space: nowrap;

    strong {
        font-weight: normal;
        color: $grey-600;
    }
}
