.md-button.md-icon-button.md-icon-button-small {
    margin: 0;
    padding: 9px 0;
    width: 36px;
    height: 36px;

    md-icon {
        font-size: 18px;
    }
}

td:first-child .md-button.md-icon-button.md-icon-button-small {
    margin-left: -9px;
}

md-icon.s48 {
    font-size: 64px;
    width: 64px;
    height: 64px;
}
