.strong {
    font-weight: bold;
}

.em {
    font-style: italic;
}

.md-whiteframe-1dp {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 2px 1px -1px rgba(0, 0, 0, .12);
}

.md-whiteframe-2dp {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12);
}

.gray-page {
    background-color: #f5f5f5;
}

.white-on-pattern {
    text-shadow: 1px 1px 0px rgba(0,0,0,0.5);
}

.text-transform-none {
    text-transform: none;
}
