.thumbnail-modal {
    flex-basis: 504px;

    .md-toolbar-tools {
        margin-bottom: -64px;

        button { background-color: rgba(255, 255, 255, .33);}
        button:hover { background-color: rgba(255, 255, 255, .5) !important;}
    }

    md-dialog-content {
        padding: 0;
    }
}
