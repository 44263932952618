.alert {
    border-radius: 3px;
    padding: 16px 20px;

    .close {
        border-radius: 0 3px;
        float: right;
        margin: -17px -20px 0 20px;
        background-color: rgba(18, 18, 18, .2);
        transition: background-color 100ms cubic-bezier(.1, .25, .1, 1);
        border: none;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        position: relative;
        vertical-align: top;
        text-indent: -9000px;
        width: 24px;
        &:hover {
            background-color: rgba(18, 18, 18, .5);
        }
        &:before,
        &:after {
            background: #fff;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            margin-left: -25%;
            margin-top: -1px;
            position: absolute;
            top: 50%;
            width: 50%;
        }
        &:before { transform: rotate(45deg); }
        &:after { transform: rotate(-45deg); }
    }
}
