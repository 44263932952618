.mono-text {
    font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hint {
    color: #808080;
    font-size: 12px;
    left: 2px;
    letter-spacing: .02em;
    line-height: 16px;
    position: absolute;
    right: auto;
    top: 39px;
}

.md-select-menu-container {
    z-index: 1050;
}

md-select-menu.md-default-theme md-content md-option[selected], md-select-menu md-content md-option[selected] {
    color: $brand-info;
}
